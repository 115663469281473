<template>
    <v-container fluid>
      <v-expand-transition mode="out-in">
        <v-row dense class="justify-end" v-if="players.length > 0 && !!ratingSystem">
          <v-btn
            color="color3"
            x-small text
            @click.stop="showFilter = !showFilter"
          >
            {{showFilter ? 'hide' : 'show'}} filter
          </v-btn>
        </v-row>
      </v-expand-transition>
      <v-expand-transition mode="out-in">
        <v-row dense v-if="showFilter">
          <v-col cols="12" sm="6" lg="3">
            <v-select
              :items="ratings"
              v-model="filter.currentValue"
              label="Current Value"
              color="color3"
              item-color="success"
              chips small-chips deletable-chips multiple
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-select
              :items="['10U','11U','12U','13U','14U','15U','16U','17U','18U','U26']"
              v-model="filter.age"
              label="Age"
              color="color3"
              item-color="success"
              chips small-chips deletable-chips multiple
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-select
              :items="gradYears"
              v-model="filter.gradYear"
              label="Grad Year"
              color="color3"
              item-color="success"
              chips small-chips deletable-chips multiple
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              label="Search"
              v-model="filter.search"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-row dense>
        <v-col cols="12">
          <v-expand-transition mode="out-in">
            <v-data-table
              :headers="[
                {align: 'center', text: 'Player' , value: 'name'},
                {align: 'center', text: 'Age' , value: 'ageDivision'},
                {align: 'center', text: 'Rating Earned' , value: 'rating.ratingValue'},
                {align: 'center', text: 'Current Value' , value: 'rating.currentValue'},
                {align: 'center', text: '' , value: 'ageDivision'}
              ]"
              :items="filteredPlayers"
              class="elevation-1"
              :options.sync="page"
              item-key="id"
              :loading="loading"
              :search="filter.search"
              color="color3"
              :footer-props="{
                'items-per-page-options': [10,25,50,100,-1]
              }"
            >
            <template v-slot:[`item`]="{item}">
              <tr @click="selectedId = item">
                <td class="text-center">{{item.name}}</td>
                <td class="text-center">{{item.ageDivision}} ({{item.gradYear || 'NA'}})</td>
                <td class="text-center">{{item.rating.rating | ratingDisplay}}</td>
                <td class="text-center">{{item.rating.currentRating | ratingDisplay}}</td>
                <td class="text-end">
                  <v-btn color="color3" text small @click="select(item)">
                    view history
                    <v-icon class="ml-2">fas fa-caret-right</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            </v-data-table>
          </v-expand-transition>
        </v-col>
      </v-row>
      <v-dialog
        v-model="dialog"
        scrollable
        max-width="500px"
        transition="dialog-transition"
      >
        <rating-history-card
          v-if="selected"
          :player="selected"
          @close="dialog=false"
          :active="dialog"
          :load="true"
        ></rating-history-card>
      </v-dialog>
    </v-container>
</template>

<script>
import RatingHistoryCard from '@/components/Ratings/RatingHistoryCard'
import { ratingDisplay } from '@/Filters'

export default {
  props: ['players', 'loading', 'ratingSystem', 'gradYears'],
  data () {
    return {
      page: {
        multiSort: true,
        sortBy: ['rating.ratingValue', 'rating.currentValue'],
        sortDesc: [1, 1],
        itemsPerPageOptions: [10, 25, 50, -1]
      },
      showFilter: false,
      filter: {
        search: null,
        currentValue: [],
        age: [],
        gradYear: []
      },
      dialog: false,
      selected: null
    }
  },
  computed: {
    filteredPlayers () {
      let base = this.players
      if (this.filter.currentValue.length) {
        const v = this.filter.currentValue.map(m => m.value)

        base = base.filter(f => {
          return v.includes(f.rating.currentRating)
        })
      }
      if (this.filter.age.length) {
        const v = this.filter.age

        base = base.filter(f => {
          return v.includes(f.ageDivision)
        })
      }
      if (this.filter.gradYear.length) {
        const x = this.filter.gradYear

        base = base.filter(f => {
          return x.includes(f.gradYear)
        })
      }
      return base
    },
    ratings () {
      return this.ratingSystem.ratings.map(m => {
        return {
          text: ratingDisplay(m.rating),
          value: m.rating
        }
      })
    }
  },
  methods: {
    select (player) {
      console.log(this.filteredPlayers.map(m => m.id))
      this.selected = player
      this.dialog = true
    }
  },
  components: {
    RatingHistoryCard
  }
}
</script>
