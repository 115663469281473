<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>
        Rating History
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="color1 color1Text--text"
        small fab
        @click.stop="$emit('close')"
      >
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pb-0">
      <div class="title">{{player.name}}
        <v-btn
          color="color3 color3Text--text"
          x-small fab :to="`/player/${player.id}`"
        >
          <v-icon>fas fa-user-circle</v-icon>
        </v-btn>
      </div>
      <div class="subtitle">Current Rating: {{player.rating.currentRating}}</div>
      <v-divider></v-divider>
      <v-expand-transition>
        <loading-bar :value="loading" v-if="loading"></loading-bar>
      </v-expand-transition>
      <v-card v-for="rating in ratings" :key="rating.id" class="my-2">
        <v-card-text>
          <b>{{rating.dtEarned.format('MMM YYYY')}} - Earned a rating of {{rating.rating | ratingDisplay}}</b> for finishing {{rating.finish | ordinal}} in {{rating.division}} at {{rating.tournament}}
          <v-simple-table dense>
            <tbody>
              <tr
                v-for="(r, i) in rating.values"
                :key="r.value"
              >
                <td :class="{'grey--text': r.valid, 'error--text': !r.valid}">{{r.valid ? 'Will be d' : 'D'}}owngraded to {{rating.getNext(i) | ratingDisplay}} on {{r.validTill |shortDate}}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions class="pt-0 justify-center">
      <v-btn
        color="color3"
        text small
        @click.stop="showAll = !showAll"
        v-if="player.ratings.length > 1"
      >
        {{showAll ? 'hide' : 'show'}} all
      </v-btn>
      <v-btn
        color="color3"
        text x-small fab
        @click.stop="getRatings"
      >
        <v-icon>fas fa-redo</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: ['player', 'active', 'load'],
  data () {
    return {
      showAll: false,
      loading: false
    }
  },
  computed: {
    ratings () {
      return this.showAll ? this.player.ratings : [this.player.ratings[0]]
    }
  },
  methods: {
    getRatings () {
      this.loading = true
      const p = this.player
      this.$VBL.ratings.getPlayer(p.id)
        .then(r => {
          const dto = r.data.find(f => f.id === p.id && f.systemId === p.systemId)
          p.update({
            ratings: dto.ratings
          })
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
        })
    },
    onActiveChange (v) {
      if (v) {
        if (this.load) this.getRatings()
      } else {
        this.showAll = false
      }
    }
  },
  watch: {
    active: 'onActiveChange'
  },
  mounted () {
    this.onActiveChange(this.active)
  }
}
</script>
