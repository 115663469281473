<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text" dense flat>
      Rated Players
    </v-toolbar>
    <player-ratings-table
      :players="results"
      :loading="loading"
      :ratingSystem="ratingSystem"
      :gradYears="gradYears"
    ></player-ratings-table>
  </v-card>
</template>

<script>
import PlayerRatings from '@/classes/PlayerRatings'
import PlayerRatingsTable from '@/components/Ratings/PlayerRatingsTable'
import { firstBy } from 'thenby'

export default {
  props: ['ratingSystem', 'username'],
  data () {
    return {
      summaries: [],
      results: [],
      tournamentId: null,
      loading: false,
      page: {},
      search: null,
      dialog: false,
      selected: null,
      showAll: false,
      gradYears: []
    }
  },
  methods: {
    getResults () {
      this.loading = true
      this.$VBL.ratings.getPlayerList(this.ratingSystem.id)
        .then(r => {
          this.results = r.data.map(m => new PlayerRatings(m))
          this.gradYears = [...new Set(this.results.filter(f => !!f.gradYear).map(m => m.gradYear))].sort(firstBy(Number))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    select (player) {
      this.selected = player
      this.dialog = true
    }
  },
  watch: {
    'ratingSystem.id': 'getResults'
  },
  mounted () {
    this.getResults()
  },
  components: {
    PlayerRatingsTable
  }
}
</script>
